import React, { useEffect } from "react"

const TestPage = (props) => {

    useEffect(() => {
        console.log(props);
    }, [])

    return <>Das ist ein Test</>
    
}

export default TestPage